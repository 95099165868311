body {
  font-family: 'Pridi', serif !important;
}
.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.insku-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.insku-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.insku-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.insku-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.insku-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.insku-toast .swal2-title {
  color: white;
}

.insku-toast .swal2-close {
  color: white;
}

.insku-toast .swal2-html-container {
  color: white;
}
