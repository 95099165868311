@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pridi';
}

body {
  @apply font-pridi;
}
@layer utilities {
  .scrollbarTable::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .scrollbarTable::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #34353580;
  }

  .scrollbarTable::-webkit-scrollbar-thumb {
    background: #34353580;
    border-radius: 100vh;
    border: 1px solid #f6f7ed;
  }

  .scrollbarTable::-webkit-scrollbar-thumb:hover {
    background: #34353580;
  }
}

.my-custom-width {
  max-width: 12rem;
}
.my-custom-width-sub {
  max-width: 10rem;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: grey;
  cursor: pointer;
}
.scrollbar-custom::-webkit-scrollbar-track {
  background-color: lightgrey;
  border-radius: 10px;
  cursor: pointer;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
